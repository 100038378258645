import Data from "../data/data.json";
import { Career } from "./career";
import { useState } from "react";

export const Careers = ({handleApplyClick}) => {

  const [showDetail, setShowDetail] = useState(false);
  const [positionIndex, setPositionIndex] = useState(null);
  const [isClosing, setIsClosing] = useState(false);
  
  const handlePositionClick = (index) => {
    setPositionIndex(index);
    setShowDetail(true);
    setIsClosing(false);
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setShowDetail(false);
    }, 500);
    
  };
  

  return (
    <div id="careers" >
      <div className="container">

        <div className="section-title">
          
          <h2>Careers</h2>
          
        </div>
        {showDetail && (
        <div className={`career-detail-modal ${isClosing ? 'hide' : 'show'}`}>
          <Career onClose={handleClose} positionIndex={positionIndex} apply={handleApplyClick}/>
        </div>
      )}

        <div className="row text-left">
          <div className="col-xs-12">
            <p>
              We are building a world class operation from scratch, created by people with a shared passion for solving challenging problems in one of the most competitive fields in the world—the global financial markets. We are looking for highly capable researchers and engineers with a spirit of curiosity, committed to lifelong learning, and energized by applying science and technology to build the world’s best prediction machine.
            </p>
          
            <h3>Join Our Team at Armada Securities Trading</h3>
                          
            <p>At Armada, we're dedicated to pioneering in the tech industry. With over 20 years of experience, we've been at the forefront of innovation, pushing the boundaries of what's possible. Our team is a mosaic of talents, coming together to build solutions that make a difference in the world.</p>
                
            <div className="why">
            <h3>Why Work With Us?</h3>
            <ul>
              <li><strong>Innovative Projects:</strong> Engage with cutting-edge technology and innovative projects that challenge the status quo.</li>
              <li><strong>Growth Opportunities:</strong> With a wide range of professional development programs, we ensure your career is always moving forward.</li>
              <li><strong>Diverse Team:</strong> Join a global team of thinkers and doers, where your unique perspective is valued and inclusivity is championed.</li>
            </ul>
            </div>
          
            <h3>Our Culture</h3>
            <p>At Armada, we believe in a culture of openness, creativity, and mutual respect. Our values are the foundation of our work, driving us to achieve excellence while fostering an environment where everyone can thrive.</p>          
          
            <h3>Available Positions</h3>
          
            <ul>
              {Data.Careers.map((c, i) => (
                <li key={i}>
                  <div >
                    {/* <h4><a href={`#position-${i}`}>- {c.title}</a></h4> */}
                    <h4 onClick={() => handlePositionClick(i)} ><a style={{ cursor: 'pointer' }} >- {c.title}</a></h4>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        
        {/* {Data.Careers.map((c, i) => (
          <div key={i} id={`position-${i}`} className="text-left" style={{ marginTop: "20px", paddingTop: "90px" }}>
            <h4>{c.title}</h4>
            <h5>Overview</h5>
            <p>{c.position}</p>
            <h5>Requirements</h5>
            {c.requirements.map((r, j) => (
              <p key={j}>- {r.r}</p>
            ))}
            <a
              href="#contact"
              style={{ fontSize: 20 }}
              onClick={() => handleApplyClick(c.title)}
            >
              Apply Now
            </a>
          </div>
        ))} */}
      
      </div>
    </div>
  );
};



import React, { useState, useEffect, useRef } from "react";

export const Navigation = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  // Close menu when a menu item is clicked
  const handleMenuItemClick = () => {
    setIsMenuOpen(false);
  };

  // Close menu when clicked outside the menu
  const handleClickOutsideMenu = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    // Add event listener to document body when menu is open
    if (isMenuOpen) {
      document.addEventListener("click", handleClickOutsideMenu);
    } else {
      // Remove event listener when menu is closed
      document.removeEventListener("click", handleClickOutsideMenu);
    }

    // Clean up the event listener when component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutsideMenu);
    };
  }, [isMenuOpen]);

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className={`navbar-toggle collapsed ${isMenuOpen ? "open" : ""}`}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll logo-link" href="#page-top">
            <img src="../../img/A_logo.png" alt="Your Logo" />{" "}
            <span className="navbar-brand-name">ARMADA</span>&nbsp;
            <span style={{ color: "#3064FF", fontWeight: "bold" }}>ST</span>
          </a>{" "}
        </div>

        <div
          className={`collapse navbar-collapse ${
            isMenuOpen ? "in" : ""
          }`}
          id="bs-example-navbar-collapse-1"
          ref={menuRef}
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a
                href="#about"
                className="page-scroll"
                onClick={handleMenuItemClick}
              >
                About Us
              </a>
            </li>

            <li>
              <a
                href="#careers"
                className="page-scroll"
                onClick={handleMenuItemClick}
              >
                Careers
              </a>
            </li>
            <li>
              <a
                href="#contact"
                className="page-scroll"
                onClick={handleMenuItemClick}
              >
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

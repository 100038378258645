import React from "react";
import { Image } from "./image";
import IData from "../data/data.json";

export const About = (props) => {

  // const career = IData.Careers.filter(career => career.careerid === id); 
  const images = IData.Gallery;
  
  
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="about-text col-xs-12">
            <h2>About Us</h2>
            <p>{props.data ? props.data.paragraph : "loading..."}</p>
            <div style={{textAlign: 'center'}}>
              <h3 style={{fontSize: 32}}>The Armada Advantage</h3>      
            </div>
            {props.data ?
                  props.data.Why.map((d,i)=>(
                    <div className="list-style">
                      <div className="col-lg-4 col-sm-4 col-xs-12">
                        <ul>
                          <h4 style={{color: '#3064FF'}}> {d.title} </h4>
                          <li key={`${d}-${i}`}>{d.desc}</li>
                        </ul>
                      </div>
                    </div>
                  )) : "loading"
            }
          </div>         
        </div>   

        {/* <div className="text-center">
          <div className="portfolio-items">
            {images
                ? images.map((d, i) => (
                    <div key={`${d.title}-${i}`} className="col-sm-6 col-md-3 col-lg-2">
                      <Image
                        title={d.title}
                        smallImage={d.smallImage}
                      />
                    </div>
                  ))
                  : "Loading..."
            }
          </div>
        </div> */}
      </div>
    </div>
  );
};

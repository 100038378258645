import React, { useState, useEffect, useRef } from "react";
import './contact.css';

const initialState = {
  name: "",
  email: "",
  subject: "",
  message: "",
};

export const Contact = ({ data, subject }) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const [messageSent, setMessageSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentSubject, setCurrentSubject] = useState(subject); // Separate state for subject

  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const handleFileChange = (e) => {
    setFile(e.target.files[0]); // Get the first file
  };

  const handleClearFile = () => {
    setFile(null);  // Clear the file state
    if (fileInputRef.current) {
        fileInputRef.current.value = "";  // Reset the file input
    }
};

  useEffect(() => {
    setState((prevState) => ({ ...prevState, subject: currentSubject }));
  }, [currentSubject]);

  useEffect(() => {    
    setCurrentSubject(subject);
  }, [subject]);

  useEffect(() => {
    if (messageSent) {
      setErrorMessage(false)
      clearState();
    }
  }, [messageSent]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => {
    console.log("Clearing state...");
    setState({ ...initialState });
    setCurrentSubject("");
    setFile(null)
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // setCurrentSubject(subject);

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("subject", currentSubject); 
    formData.append("message", message);
    if (file) {
      formData.append("file", file);
    }

    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    console.log(JSON.stringify({ name, email, currentSubject, message }))

    fetch("https://armadast.com:9590/send-email", {
    // fetch("http://127.0.0.1:9590/send-email", {
      method: "POST",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      // body: JSON.stringify({ name, email, currentSubject, message }),
      body: formData,
    })
      .then((response) => response.text())
      .then((result) => {
        setMessageSent(true);
        setTimeout(() => setMessageSent(false), 3000);
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage("Failed to send message.");
      });
  };

  const handleSubjectChange = (e) => {
    setCurrentSubject(e.target.value);
  };

  return (
    <div>
      
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we
                  will get back to you as soon as possible.
                </p>
              </div>

              {messageSent && (
                  <div className="alert alert-success" role="alert">
                    Message sent successfully!
                  </div>
                )}
                {errorMessage && (
                  <div className="alert alert-danger" role="alert">
                    {errorMessage}
                  </div>
                )}
              
              
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        value={name}
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        value={email}
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        id="subject"
                        name="subject"
                        className="form-control"
                        placeholder="Subject"
                        required
                        value={currentSubject}
                        onChange={handleSubjectChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    value={message}
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div className="col-md-12">
                  <div className="file-input-container form-group">
                    <div className="file-input-button" onClick={() => fileInputRef.current.click()}>
                      <i className="fa fa-upload file-icon"></i> Upload CV
                    </div>
                    <input
                      ref={fileInputRef}
                      type="file"
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                    />
                  </div>
                  
                  {file && (
                    <div className="file-display">
                      Selected file: {file.name}
                      <button onClick={handleClearFile} className="clear-button" style={{ marginLeft: '20px' }}>
                        Clear
                      </button>
                    </div>
                  )}
                </div>                
                
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              {/* <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {data ? data.address : "loading"}
              </p> */}
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {data ? data.email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social"></div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer"></div>
    </div>
  );
};

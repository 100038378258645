import React from "react";
import data from "../data/data.json"
import { useRef, useEffect } from "react";
import './contact.css'; 

export const Career = ({ onClose, positionIndex, apply }) => {

  const position = data.Careers[positionIndex]

  const modalRef = useRef();

  const applyNow = () => {
    apply(data.Careers[positionIndex].title);
    onClose();  
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [onClose]);
  
  return (
    <div className="career-detail-modal">
      <div className="container" ref={modalRef}>
        <div className="modalHeader">
          <button className="close-btn" onClick={onClose} >
            <i class="fa fa-times"></i>
          </button>
        </div>

        <div className="modalBody">
          <h1>Join Our Team at Company</h1>
          <br/>
      
          <h2>Why Work With Us?</h2>
          <p>At Armada ST, we're dedicated to pioneering in the tech industry. With over 20 years of experience, we've been at the forefront of innovation, pushing the boundaries of what's possible. Our team is a mosaic of talents, coming together to build solutions that make a difference in the world.</p>
      
          
          <ul>
            <li><strong>Innovative Projects:</strong> Engage with cutting-edge technology and innovative projects that challenge the status quo.</li>
            <li><strong>Growth Opportunities:</strong> With a wide range of professional development programs, we ensure your career is always moving forward.</li>
            <li><strong>Diverse Team:</strong> Join a global team of thinkers and doers, where your unique perspective is valued and inclusivity is championed.</li>
          </ul>

          <h2 className="postitle">{position.title}</h2>
      
          <h3>About the Position</h3>
          <p>{position.position}</p>         
          <h3>Requirements</h3>
          <ul>
            {position.requirements.map((req, index) => (
              <li key={index}>- {req.r}</li>  // List all requirements
            ))}
          </ul>
          <p><strong><a
                href="#contact"
                style={{ fontSize: 20 }}
                onClick={applyNow}
              >
                Apply Now
                </a></strong> </p>        
      
          <h2>Our Culture</h2>
          <p>At Armada ST, we believe in a culture of openness, creativity, and mutual respect. Our values are the foundation of our work, driving us to achieve excellence while fostering an environment where everyone can thrive.</p>
        </div>
      </div>
    </div>
  );
};
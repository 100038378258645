import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Careers } from "./components/careers";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import {Video} from "./components/video"
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  const [subject, setSubject] = useState("")
  
  const handleApplyClick = (title)=>{
    console.log(title)
    setSubject(title);
  }

  return (
    <Router>
      <div className="video-container">  
        <Video src="/video/vid1.webm" mobileSrc="/video/mobile.webm" />     
        <div className="header-navigation-container">
          <Navigation />
          <div className="video-overlay"></div>
          <Header data={landingPageData.Header} />          
        </div>
      </div>
        <Routes>
          <Route path="/" element={
            <>
              <About data={landingPageData.About} />
              <Careers data={landingPageData.Gallery} handleApplyClick={handleApplyClick}/>
              <Contact data={landingPageData.Contact} subject={subject} />
            </>
          } />
        </Routes>
      
    </Router>
  );
};

export default App;

import React, { useState, useEffect } from 'react';
import useMediaQuery from './useMediaQuery';

export const Video = ({ src, mobileSrc }) => {
  const isMobile = useMediaQuery('(max-width: 768px)'); // Adjust the breakpoint as needed
  const [videoSrc, setVideoSrc] = useState(isMobile ? mobileSrc : src);

  useEffect(() => {
    setVideoSrc(isMobile ? mobileSrc : src);
  }, [isMobile, src, mobileSrc]);

  return (
    <video preload="auto" autoPlay playsInline muted loop id="background-video" >
      <source src={videoSrc} type="video/webm" />
      Your browser does not support the video tag.
    </video>
  );
};
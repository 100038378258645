import React from "react";
import './terminal.css';
// import './header.css';

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        {/* <video autoPlay muted loop id="background-video">
          <source src="/video/vid1.webm" type="video/webm" />
          Your browser does not support the video tag.
        </video> */}
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-10 intro-text">
              
                <h1>
                  {props.data ? props.data.title : "Loading"}  
                </h1>                
                <h1 className="intro-text-secondary">
                {props.data ? props.data.title2 : "Loading"} 
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
